<template>
  <div style='display: inline;'>

    <el-autocomplete
      v-model='value'
      :fetch-suggestions='querySearch'
      placeholder='通过昵称或手机号检索'
      @change='handleSelect'
      @select='handleSelect'
      clearable>
    </el-autocomplete>
    <span v-show='loading'><i class='el-icon-loading'></i>检索中...</span>
  </div>
</template>

<script>
// import { debounce } from 'lodash'

export default {
  name: 'UserSearch',
  props: {
    keyword: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.keyword
      },
      set(val) {
        this.$emit('update:keyword', val)
      }
    },
    // debouncedQuerySearch: {
    //   get() {
    //     // 创建防抖后的方法
    //     return debounce(this.querySearch, 500)
    //   },
    //   cache: true
    // }
  },
  data() {
    return {
      loading: false,
      timeout: null
    }
  },
  methods: {
    async querySearch(qs, cb) {
      // 模拟异步请求数据
      this.loading = true
      let { list } = await this.$api.searchUserCommon(qs)
      this.loading = false
      cb(list)
      // setTimeout(() => {
      //   cb([queryString, queryString + '1', queryString + '2']);
      // }, 200);
    },
    handleSelect(item) {
      // console.log('item', item)
      this.$emit('selected', item)
    },
    // 搜索用户,qs请求参数，cb回调函数，详见组件用法
    async searchUser(qs, cb) {
      this.loading = true
      let list = await this.$api.searchUserCommon(qs)
      this.loading = false
      cb(list)
    },
    clear() {
      this.value = ''
    }

  }
}
</script>

<style scoped>

</style>
